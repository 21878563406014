.head span,
.security .security-card h3 {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
    -webkit-text-fill-color: transparent;
}
button.btn.common-btn,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400 !important;
}
*,
.home ul,
.navbar ul li,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
    padding: 0;
    margin: 0;
}
.intro,
body {
    position: relative;
}
.navbar ul li a,
footer .copyright p {
    text-transform: uppercase;
    letter-spacing: 1px;
}
.card-inner .wallet-address span,
.copy-wrap a span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.bid-list .content span,
.common-modal span.tier,
.copy-wrap a,
.explore .card-left h4,
.form-wrapper > span a,
.head span,
.modal-body .clear,
.phase .inner h3,
.profile .inner .bottom span.address,
.security .security-card h3,
.sub-text-pattern,
.text-pattern {
    -webkit-text-fill-color: transparent;
}
@font-face {
    font-family: "Gang Wolfik";
    src: url("./static/fonts/Gang-Wolfik.woff2") format("woff2"), url("./static/fonts/Gang-Wolfik.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: thewitcher;
    src: url("./static/fonts/thewitcher.woff2") format("woff2"), url("./static/fonts/thewitcher.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
body {
    font-family: Montserrat, sans-serif !important;
    background: #1c2228 !important;
}
.navbar ul li a,
h1,
h2 {
    font-family: "Gang Wolfik" !important;
}
body > iframe {
    display: none;
}
html {
    scroll-behavior: smooth;
}
body,
input,
textarea {
    padding: 0;
    margin: 0;
    line-height: 1.5;
}
a {
    display: inline-block;
}
a:hover {
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff;
}
h1,
h2 {
    line-height: 1.4 !important;
    text-transform: uppercase;
}
p,
ul li {
    font-weight: 300;
}
.phase .phase-number span,
.vesting-chart *,
h3,
h4,
h5,
h6 {
    font-family: Montserrat, sans-serif !important;
}
.home h1,
.home h2 {
    font-family: thewitcher !important;
    line-height: 1 !important;
}
.home h2 {
    font-size: 65px !important;
}
.text-pattern {
    background-image: url(./static/images/text-pattern.png);
    -webkit-background-clip: text;
}
.sub-text-pattern {
    background-image: url(./static/images/sub-text-pattern.png) !important;
    -webkit-background-clip: text;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 {
    font-size: 56px !important;
}
h2 {
    font-size: 48px !important;
}
h3 {
    font-size: 32px;
    line-height: 1.2;
}
p,
ul li {
    font-size: 16px;
}
p {
    line-height: 1.5;
    color: #a4a7a9;
}
ol,
ul {
    list-style: none;
}
.home .intro .content,
.two-wrap .vesting-chart .inner,
img {
    max-width: 100%;
}
.footer.common-btn {
    position: sticky;
    bottom: 15px;
    margin: 0 15px 15px auto !important;
    width: 280px;
    height: 60px;
    background-size: 280px 60px;
    padding-right: 20px;
    z-index: 3;
}
@media screen and (min-width: 1200px) {
    .container {
        max-width: 1200px !important;
    }
}
.head span {
    font-size: 30px;
    font-weight: 600;
    -webkit-background-clip: text;
}
.common-btn,
button.btn.common-btn,
button.common-btn.btn.btn-primary {
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-design.png) !important;
    background-size: contain;
    background-repeat: no-repeat !important;
    background-position: center;
    color: #fff !important;
    letter-spacing: 1px;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    width: 249px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 7px;
    outline: 0;
    border: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}
.common-btn + .common-btn {
    margin-top: 20px !important;
}
.common-btn.pr {
    letter-spacing: 0.2px;
    font-size: 15px;
    padding-right: 30px;
    width: 270px;
    height: 60px;
}
.common-btn img {
    position: absolute;
    right: 10px;
}
.head {
    margin-bottom: 20px;
}
ul li {
    font-style: italic;
    padding-left: 43px;
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/list-icon.png);
    background-size: 23px;
    background-position: 0 0;
    background-repeat: no-repeat;
    margin-bottom: 15px;
    color: #fff;
}
.about .row,
.benefits .row,
.intro .row {
    align-items: center;
}
.intro,
.navbar ul {
    align-items: center;
    display: flex;
}
header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}
.navbar {
    padding: 30px 0 !important;
}
.navbar ul {
    width: calc(50% - 100px);
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 0;
}
.navbar ul li {
    background-image: none;
}
.navbar ul li a {
    color: #fff;
    font-size: 28px;
    font-style: normal;
    line-height: 0.7;
}
.intro {
    padding: 200px 0 100px;
    min-height: 100vh;
}
.intro img,
.intro video {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
}
.intro .img-wrap {
    max-width: 250px;
    margin: 0 auto;
}
.intro:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
}
.intro .head span {
    font-size: 50px;
    text-transform: initial;
    display: inline-block;
    margin-bottom: 10px;
}
.intro .content {
    max-width: 624px;
    position: relative;
    z-index: 2;
}
.about .content p,
.intro .content p {
    margin-bottom: 50px;
    color: #fff;
}
.intro .dragon-btn {
    animation: 4s infinite mymove;
}
@keyframes mymove {
    0%,
    100% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(10px);
    }
}
.about {
    padding: 100px 0;
    position: relative;
    overflow: hidden;
}
.about:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
}
.about .content,
.join-us,
.phase {
    position: relative;
}
.benefits.hederagon,
.security {
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/gradient-banner.png);
    background-size: 100% 800px;
}
.benefits.hederagon,
.security,
.tokenomics {
    background-position: center top;
}
.benefits.hederagon,
.phase .phase-number,
.phase:before,
.security,
.tokenomics {
    background-repeat: no-repeat;
}
.about .head {
    max-width: 592px;
}
.about .content {
    z-index: 3;
}
.about video {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
}
.security {
    padding: 100px 0 80px;
    overflow: hidden;
}
.home .security .col-lg-6,
.progress-bar-wrap,
.security .col-xl-4,
.vesting-chart .inner {
    margin-bottom: 30px;
}
.security .security-card {
    padding: 30px;
    border: 1px solid #07b2d4;
    border-radius: 16px;
    margin-bottom: 30px;
}
.common-modal.react-responsive-modal-modal.learn-more-modal p:last-child,
.security .security-card p:last-child,
.security .security-card:last-child,
.staking .box .info-box > div:last-child p {
    margin-bottom: 0;
}
.security .col-lg-6:first-child .security-card {
    height: 100%;
    display: flex;
    align-items: center;
}
.security .security-card img {
    width: 64px;
    height: 64px;
    object-fit: contain;
    margin-bottom: 20px;
}
.security .security-card h3 {
    font-size: 24px;
    font-weight: 600;
    -webkit-background-clip: text;
    margin-bottom: 20px;
}
.phase .inner p b,
.phase .inner span,
.staking .box .info-box p {
    font-weight: 500;
    color: #fff;
}
.benefits .common-btn,
.security .btn-wrap .common-btn {
    margin: 60px auto 0;
}
.benefits,
.roadmap {
    padding: 100px 0;
}
.benefits.hederagon .row,
.home .about .row {
    align-items: flex-start;
}
.benefits.hederagon .img-wrap img {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    display: block;
}
.benefits ul li {
    padding-left: 76px;
    font-size: 20px;
    margin-bottom: 15px;
    background-size: 46px;
    min-height: 46px;
    display: flex;
    align-items: center;
}
.benefits video,
.common-modal.react-responsive-modal-modal.swap-modals .form-group,
.ecosystem img,
.mint-nft video,
.tokenomics .inner .chart-wrap img,
.tokenomics .inner .table-wrap {
    width: 100%;
}
video::-webkit-media-controls {
    display: none;
}
.token-airdrop video {
    width: 100%;
    margin: 0 auto;
}
.phase {
    display: flex;
    align-items: flex-start;
    margin-bottom: 70px;
}
.phase:before {
    position: absolute;
    content: "";
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/phase-stroke.png);
    width: 10px;
    height: calc(100% - 46px);
    top: 112px;
    left: 48px;
    background-size: contain;
}
.common-modal .react-responsive-modal-closeButton,
.form-group.textarea:after,
.form-group.textarea:before,
.home .phase:before,
.phase:last-child::before,
.vesting-chart .apexcharts-data-labels,
button.bid-btn.common-btn img {
    display: none;
}
.phase .phase-number {
    width: 106px;
    height: 106px;
    margin-right: 40px;
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/phase.png);
    background-size: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-position: center;
    font-family: "Gang Wolfik";
    font-size: 37px;
    padding-right: 5px;
    padding-top: 7px;
    transition: 0.5s ease-in-out;
}
.phase .phase-number span {
    font-size: 8px;
    transform: rotate(-90deg);
    margin-left: 4px;
    width: 2px;
    margin-top: 21px;
}
.phase .phase-number.rotate {
    transform: rotate(180deg);
}
.phase .inner {
    background: rgba(255, 255, 255, 0.02);
    border-radius: 16px;
    padding: 24px;
    width: calc(100% - 146px);
}
.phase .inner span {
    font-size: 18px;
    line-height: 1.3;
}
.phase .inner h3 {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
    font-size: 24px;
    font-weight: 600;
    -webkit-background-clip: text;
    margin-bottom: 30px;
}
.phase .inner p {
    margin-bottom: 5px !important;
}
.tokenomics {
    padding-bottom: 80px;
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/tokenomics-bg.png);
    background-size: cover;
}
.bid-list .content span,
.buy-arbitrum .progress .progress-bar,
.item-detail .item-views span,
.join-separator,
.join-us,
.tabs-wrapper .nav-tabs .nav-item button.active {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
}
.blogs .head,
.buy-arbitrum .head,
.ecosystem .head,
.edit-profile,
.game .inner p,
.mint-nft .head,
.profile .bottom-info .info,
.tokenomics .head {
    text-align: center;
}
.tokenomics .inner .chart-wrap {
    width: 500px;
    margin: 40px auto 0;
    position: relative;
}
.tokenomics .inner .chart-wrap .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.tokenomics .inner .chart-wrap .content h3 {
    font-size: 48px;
    font-weight: 700 !important;
    margin: 0;
    line-height: 1;
}
.tokenomics .inner .chart-wrap .content span {
    font-size: 32px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 3px;
}
.table-wrap table {
    width: 100%;
    border: 1px solid #07b2d4;
    border-radius: 16px;
    padding: 10px 15px;
    border-collapse: separate;
}
.tokenomics .inner .table-wrap table tr td:first-child {
    width: 43px;
}
.table-wrap table tr td {
    color: #fff;
    font-size: 14px;
    padding: 12px 10px;
}
.table-wrap table tr th {
    color: #fff;
    font-size: 15px;
    padding: 12px 10px 0;
}
.join-separator {
    height: 8px;
    width: 100%;
    margin: 0;
}
.join-us {
    margin: 5px 0;
    padding: 80px 0;
}
.join-us:after,
.join-us:before {
    position: absolute;
    content: "";
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/join-us-pattern.png);
    background-size: cover;
    width: 296px;
    height: 296px;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
}
.join-us:after {
    left: auto;
    right: 5px;
    transform: translateY(-50%) rotate(180deg);
}
.join-us .content {
    text-align: center;
    max-width: 1088px;
    margin: 0 auto;
}
.join-us .content p {
    color: #fff;
    margin-top: 20px;
}
.faqs {
    padding-top: 100px;
}
.faqs .row {
    align-items: flex-end;
}
.faqs .head,
.game .inner h2,
.home .benefits .head {
    margin-bottom: 50px;
}
.faqs .content > div {
    border: 1px solid #07b2d4;
    border-radius: 16px;
    margin-bottom: 30px;
}
.faqs .content > div h3 {
    padding: 20px 65px 20px 25px;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    position: relative;
}
.faqs .content > div h3:after {
    position: absolute;
    content: "";
    right: 20px;
    top: 50%;
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/arrow-down.png);
    transform: translateY(-50%) rotate(-180deg);
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
}
.faqs .content > div h3.show:after {
    transform: translateY(-50%) rotate(0);
}
.faqs .content > div .body {
    padding: 0 25px 5px;
}
.buy-arbitrum,
.buy-nft,
.dao-wrapper,
.edit-profile {
    min-height: calc(100vh - 76px);
    padding: 200px 0 50px;
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/gradient-banner.png);
    background-size: 100% 800px;
    background-position: center top;
    background-repeat: no-repeat;
}
.buy-arbitrum .progress-wrap {
    max-width: 380px;
    margin: 0 auto 30px;
}
.buy-arbitrum .progress {
    background: #d9d9d9;
    height: 2px;
}
.buy-arbitrum .table-wrap {
    width: 600px;
    max-width: 100%;
    margin: 50px auto 0;
    text-align: center;
}
.buy-arbitrum .head span:last-child {
    font-size: 14px;
}
.buy-arbitrum .info,
.profile .inner .bottom,
.staking .box .info-box > div,
.view-token,
footer .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buy-arbitrum .info span {
    font-weight: 300;
    font-size: 14px;
    color: #fff;
    margin: 5px 0;
}
.buy-arbitrum .bottom.info span {
    color: #a4a7a9;
    display: inline-block;
}
.buy-arbitrum .common-btn,
.common-modal .modal-dialog .common-btn,
.common-modal.react-responsive-modal-modal .common-btn {
    margin: 0 auto;
}
.form-group input,
.form-group select,
.form-group textarea,
.input-wrap {
    background: #262b31 !important;
    border: 1px solid #07b2d4 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    color: #a4a7a9;
    padding: 15px;
    height: 56px;
    width: 100%;
    position: relative;
    z-index: 2;
    border-radius: 0;
    font-size: 16px;
    outline: 0;
}
.explore,
.mint-nft,
.profile {
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/gradient-banner.png);
}
.explore,
.mint-nft,
.profile,
.timer p {
    background-position: center top;
}
.ecosystem,
.explore,
.mint-nft,
.profile,
.timer p {
    background-repeat: no-repeat;
}
.item-detail .item-views span {
    color: #fff;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    padding: 4px 8px;
    align-items: center;
    margin-bottom: 20px;
}
.tabs-wrapper .nav-tabs {
    border-bottom: 1px solid #07b2d4;
}
.tabs-wrapper li {
    background-image: none;
    padding: 0;
}
.tabs-wrapper .nav-tabs .nav-item button {
    color: #fff;
    border: 0;
}
.tabs-wrapper .tab-content {
    padding: 15px;
}
.price-card .form-group {
    margin: 0 0 24px 30px !important;
}
.buy-nft .price-card p {
    margin-bottom: 0 !important;
}
button.bid-btn.common-btn {
    background-image: none !important;
    padding: 10px 15px !important;
    width: auto !important;
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%) !important;
    height: auto;
    border-radius: 4px;
    display: inline-block;
    margin: 10px 10px 10px 0;
    min-width: 150px;
}
.price-card input[type="radio"] {
    margin-right: 5px;
}
.price-card input[type="radio"] + label {
    color: #fff;
    font-size: 16px;
    margin-right: 30px;
}
.common-modal .modal-dialog .form-wrapper label {
    margin-top: 10;
}
.common-modal .modal-dialog,
.common-modal.react-responsive-modal-modal {
    width: 490px !important;
    background: #1c2228;
    border-radius: 16px;
    margin: auto;
    max-width: 92%;
}
.common-modal.react-responsive-modal-modal.learn-more-modal {
    width: 900px !important;
}
.common-modal.react-responsive-modal-modal.learn-more-modal * {
    text-align: left;
}
.common-modal.react-responsive-modal-modal.learn-more-modal p {
    font-size: 15px;
    margin-bottom: 20px;
}
.common-modal.react-responsive-modal-modal.learn-more-modal ul li {
    font-size: 15px;
    color: #a4a7a9;
}
.collections-info p span,
.common-modal .form-group span,
.common-modal.react-responsive-modal-modal.learn-more-modal ul li b,
.home .faqs .content p,
.home .security .security-card p {
    color: #fff;
}
.common-modal.react-responsive-modal-modal.learn-more-modal h3 {
    font-size: 20px;
}
.common-modal.react-responsive-modal-modal.learn-more-modal .modal-body {
    padding-top: 30px;
}
.common-modal .modal-dialog .form-wrapper {
    padding: 0 20px 30px;
}
.common-modal .modal-dialog button.close {
    position: absolute;
    right: 20px;
    top: 15px;
    opacity: 1;
}
.common-modal .modal-header {
    color: #fff;
    text-align: center;
    font-size: 20px;
    justify-content: center;
    margin-bottom: 20px;
}
.common-modal .modal-dialog .modal-content {
    background: #1c2228;
    border-radius: 16px;
}
.bid-list,
.form-group-wrapper,
.staking .box .btn-wrap {
    display: flex;
    justify-content: space-between;
}
.bid-list li {
    width: 48%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}
.bid-list li .thumbnail {
    width: 70px;
    height: 70px;
    border-radius: 8px;
}
.bid-list li .thumbnail img {
    width: 100%;
    height: 100%;
}
.bid-list .content {
    width: calc(100% - 70px);
    padding-left: 12px;
}
.bid-list .content h5 {
    line-height: 1.1;
    margin: 0;
    font-size: 16px;
}
.bid-list .content p {
    margin: 0;
    margin-bottom: 10px !important;
    font-size: 13px;
}
.bid-list .content span {
    font-size: 14px;
    font-weight: 500;
    -webkit-background-clip: text;
}
.form-group textarea {
    border: 1px solid #07b2d4 !important;
    border-radius: 0;
    height: 120px !important;
    outline: 0 !important;
    max-height: 120px !important;
}
.form-group .radio-wrap {
    background: #262b31;
    border: 1px solid #07b2d4;
    border-left: 0 !important;
    border-right: 0 !important;
    color: #a4a7a9;
    padding: 5px 0;
    height: 56px;
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    max-width: 370px;
}
.form-group:after,
.form-group:before {
    content: "";
    width: 46px;
    height: 46px;
    border: 1px solid #07b2d4;
    top: 5px;
    transform: rotate(45deg);
    z-index: 1;
    background: #262b31;
    position: absolute;
}
.form-group.radio {
    max-width: 370px !important;
}
.form-group .radio-wrap p {
    width: calc(50% + 25px);
    margin: 0;
    position: relative;
}
.form-group .radio-wrap input:checked + label {
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-design.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
}
.form-group .radio-wrap p input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}
.form-group .radio-wrap p:first-child {
    margin-left: -25px;
}
.form-group .radio-wrap p:last-child {
    margin-right: -25px;
}
.form-group .radio-wrap label {
    font-size: 16px;
    color: #fff;
    padding: 0;
    margin: 0;
    display: flex;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.form-group {
    position: relative;
    max-width: calc(100% - 60px);
    margin: 0 auto 25px !important;
    width: 380px;
}
.form-group:before {
    border-right: 0;
    border-top: 0;
    border-radius: 10px;
    left: -20px;
}
.form-group:after {
    border-left: 0;
    border-bottom: 0;
    border-radius: 10px;
    right: -20px;
}
.form-group label {
    color: #a4a7a9;
    font-size: 14px;
    padding-left: 15px;
    padding-top: 10px;
}
.form-wrapper .form-group {
    margin: 0 0 20px 30px !important;
    width: 100%;
}
.form-wrapper label {
    color: #fff;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 18px;
    margin-top: 30px;
}
.form-wrapper h2 {
    font-size: 32px !important;
    margin-bottom: 10px;
}
.form-wrapper > p {
    font-size: 16px;
    margin-bottom: 12px;
}
.explore .card-left h4,
.form-wrapper > span a {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 600 !important;
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
    -webkit-background-clip: text;
}
.form-wrapper {
    max-width: 500px;
}
.filewrap {
    position: relative;
    cursor: pointer;
}
.form-group.filewrap + span {
    margin: -10px 0 20px 30px;
    display: block;
}
.filewrap .file-inner {
    background: #262b31;
    border: 1px solid #07b2d4;
    border-left: 0 !important;
    border-right: 0 !important;
    color: #a4a7a9;
    padding: 15px;
    height: 56px;
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filewrap .file-inner .common-btn {
    letter-spacing: 0;
    font-size: 13px;
    width: 155px;
    margin: 0 -28px 0 0 !important;
}
.mint-nft .mint-info:after,
.mint-nft .mint-info:before {
    width: 42px;
    height: 45px;
    transform: rotate(45deg);
    top: 5px;
    z-index: 1;
    content: "";
}
.filewrap input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.explore.vh {
    min-height: calc(100vh - 76px);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-size: 100% 800px;
}
.explore,
.profile {
    padding: 200px 0 100px;
    background-size: 100% 600px;
}
.explore .common-btn,
.mint-nft .common-btn {
    margin: 30px auto 0;
}
.explore .card-box {
    width: 100%;
    border: 1px solid #0bc3f2;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 30px;
}
.explore .card-inner {
    padding: 10px;
    position: relative;
}
.explore .card-inner > img {
    width: 100%;
    height: 246px;
    object-fit: cover;
    border-radius: 12px;
}
.explore .card-inner h3 {
    font-size: 16px;
    margin-block: 20px;
}
.explore .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 15px;
}
.explore .card-box > img {
    max-width: 246px;
    margin-inline: auto;
    margin-bottom: 20px;
}
.explore .reaction {
    position: absolute;
    top: 25px;
    right: 25px;
    border-radius: 5px;
    padding: 2px 6px;
    background-color: #1c2228;
    display: inline-block;
}
.explore .comming-soon {
    position: absolute;
    top: 25px;
    left: 25px;
    border-radius: 5px;
    padding: 3px 7px;
    background-color: #42c126;
    color: #ffff;
    font-size: 12px;
}
.explore .reaction span {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.explore .reaction img {
    padding-right: 7px;
}
.explore .card-body img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}
.explore .card-box > span {
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
    display: block;
}
.explore .card-left,
.profile .inner .upper {
    display: flex;
    align-items: flex-start;
}
.explore .card-left span {
    color: #a4a7a9;
    font-size: 12px;
    display: block;
}
.card-right-text {
    padding-left: 10px;
}
.explore .card-right span {
    background: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/card-tag-bg.png) center/66px 24px no-repeat;
    font-size: 14px;
    color: #fff;
    width: 66px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkbox-wrap label:before,
.explore .card-bottom {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
}
.explore .card-bottom .view-history img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}
.explore .card-bottom {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
}
.mint-nft .mint-info,
.mint-nft .mint-info:after,
.mint-nft .mint-info:before {
    border: 1px solid #07b2d4;
    background: #262b31;
}
.explore .card-bottom .price {
    padding-right: 31px;
}
.explore .card-bottom .bold {
    font-size: 14px;
    color: #ffff;
    font-weight: 700;
}
.explore .card-bottom .current {
    display: block;
    color: #fff;
    font-size: 12px;
}
.explore .price p span:last-child,
.explore .view-history a {
    color: #fff;
    font-size: 12px;
}
.mint-nft p {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 50px;
}
.mint-nft {
    padding: 200px 0 100px;
    background-size: 100% 800px;
}
.mint-info-wrap {
    max-width: 470px;
    margin: 0 auto 50px;
}
.mint-nft .mint-info {
    border-left: 0 !important;
    border-right: 0 !important;
    color: #a4a7a9;
    padding: 15px 0;
    height: 56px;
    width: calc(100% - 60px);
    margin: 0 auto 40px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mint-nft .mint-info:before {
    position: absolute;
    border-right: 0;
    border-top: 0;
    border-radius: 7px 10px 8px;
    left: -21px;
}
.mint-nft .mint-info:after {
    position: absolute;
    border-left: 0;
    border-bottom: 0;
    border-radius: 7px 10px 8px;
    right: -21px;
}
.mint-nft .mint-info span {
    position: relative;
    z-index: 2;
    color: #a4a7a9;
    font-weight: 500;
    font-style: italic;
    font-size: 15px;
}
.mint-nft .mint-info span:last-child {
    max-width: calc(100% - 175px);
    word-break: break-all;
}
.timer {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}
.timer p span {
    display: block;
    font-size: 14px;
    margin-top: 24px;
}
.timer p {
    color: #fff;
    font-size: 32px;
    width: 80px;
    text-align: center;
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/timer-bg.png);
    background-size: 80px;
    padding-top: 17px;
}
.apexcharts-legend,
.buy-nft .progress-bar > div > div span {
    display: none !important;
}
.blogs {
    padding: 50px 0 70px;
}
.blogs article {
    border: 1px solid #07b2d4;
    padding: 16px 16px 20px;
    border-radius: 16px;
    margin-bottom: 30px;
}
.checkbox-wrap,
.dao-wrapper p {
    margin-bottom: 24px;
}
.blogs article img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 20px;
}
.blogs article h3 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
}
.blogs article p,
.home .benefits.hederagon ul li ul,
.home .phase {
    margin: 0;
}
.dao-wrapper .form-group {
    margin: 0 0 24px 30px !important;
    width: 100%;
    max-width: 568px;
}
.dao-wrapper h3 {
    margin-top: 40px;
}
.checkbox-wrap {
    position: relative;
    display: inline-block;
}
.checkbox-wrap input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}
.checkbox-wrap label {
    font-size: 18px;
    font-weight: 300;
    padding-left: 28px;
    color: #fff;
}
.checkbox-wrap label:before {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 5px;
    left: 0;
    top: 5px;
}
.checkbox-wrap input:checked + label:after {
    position: absolute;
    content: "";
    width: 11px;
    height: 6px;
    border: 2px solid #212529;
    border-top: 0;
    border-right: 0;
    top: 11px;
    left: 3px;
    transform: rotate(-45deg);
}
.filter-wrap .common-btn {
    width: 295px;
    background-size: 295px 56px;
    padding-right: 30px;
}
.filter-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}
.filter-wrap .form-group {
    max-width: 315px;
    margin: 0 0 0 29px !important;
}
.search-wrap img {
    position: absolute;
    right: -17px;
    z-index: 2;
    top: 7px;
}
.filter-wrap .treasury-value {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 15px;
    border: 1px solid #07b2d4;
    background: #1c2228;
    border-radius: 6px;
    margin-left: auto;
    margin-right: 25px;
}
.filter-wrap .treasury-value label,
.filter-wrap .treasury-value span {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin: 0;
}
.filter-wrap .treasury-value span {
    color: rgba(255, 255, 255, 0.4);
}
.proposals-table table {
    width: 100%;
    border-spacing: 0 12px;
    border-collapse: separate;
}
.proposals-table th {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 12px;
    border-bottom: 1px solid #1f1f2c;
}
.proposals-table td {
    height: 75px;
    background: rgba(255, 255, 255, 0.02);
}
.proposals-table td,
.proposals-table td span {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 12px;
}
.proposals-table td.expired {
    color: #a4a7a9;
}
.proposals-table td.active {
    color: #42c126;
}
.proposals-table td.vote-count {
    width: 290px;
}
.proposals-table td.vote-reject img {
    margin-right: 25px;
}
.proposals-table td span:last-child,
.proposals-table td.vote-reject img:last-child {
    margin-right: 0;
}
.proposals-table td span {
    color: #a4a7a9;
    padding: 0;
    display: inline-block;
    margin-right: 22px;
}
.proposals-table td span img {
    margin-right: 6px;
    width: 28px;
}
.proposals-table .common-btn {
    width: 156px;
    height: 42px;
    font-size: 14px;
    padding-right: 15px;
}
.proposals-table .common-btn img {
    right: 6px;
}
.proposals-table td:first-child {
    border-radius: 8px 0 0 8px;
}
.proposals-table td:last-child {
    border-radius: 0 8px 8px 0;
}
.common-modal.react-responsive-modal-modal h2 {
    text-align: center;
    color: #fff;
    font-size: 18px !important;
    text-transform: initial;
    font-weight: 600;
    font-family: Montserrat, sans-serif !important;
    margin-bottom: 35px;
}
.common-modal.react-responsive-modal-modal .modal-body {
    padding: 5px 10px 30px;
}
.common-modal.react-responsive-modal-modal .form-group,
.edit-profile .form-group {
    margin-bottom: 20px !important;
}
button.common-close-btn {
    background: 0 0;
    position: absolute;
    right: 30px;
    top: 25px;
    box-shadow: none;
    border: 0;
    z-index: 5;
}
.common-modal.react-responsive-modal-modal p {
    margin-bottom: 35px;
    text-align: center;
}
.common-modal.react-responsive-modal-modal p.note {
    text-align: center;
    margin: 0 auto;
    color: #fff;
}
.buy-nft .head {
    margin-bottom: 10px;
}
.buy-nft .head h1 {
    font-size: 32px !important;
}
.buy-nft .head span {
    font-size: 20px;
    margin-bottom: 10px;
    display: block;
}
.buy-nft .img-wrap {
    border: 1px solid #07b2d4;
    border-radius: 12px;
    padding: 12px;
}
.buy-nft .img-wrap img {
    width: 100%;
    border-radius: 12px;
    object-fit: cover;
}
.buy-nft .item-interaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.buy-nft .item-interaction button {
    background: 0 0;
    box-shadow: none;
    border: 0;
}
.buy-nft .item-interaction .share-option {
    display: flex;
    align-items: center;
    gap: 10px;
}
.buy-nft .item-views {
    display: flex;
    align-items: center;
    gap: 15px;
}
.buy-nft .item-views span {
    border: 1px solid #07b2d4;
    border-radius: 5px;
    background: #1c2228;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    padding: 3px 5px;
}
.buy-nft p {
    margin-bottom: 30px !important;
}
.buy-nft .progressbar-head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2px;
}
.buy-nft .progressbar-head *,
.buy-nft .progressbar-head span {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}
.buy-nft .progressbar-head b {
    font-size: 18px;
}
.buy-nft .progress-bar {
    background: 0 0;
}
.buy-nft .progress-bar > div {
    height: 3px !important;
    border-radius: 100px !important;
    background-color: #6e7380 !important;
}
.buy-nft .progress-bar > div > div {
    height: 3px !important;
    border-radius: 100px !important;
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%) !important;
}
.limit-wrap,
.profile .inner {
    display: flex;
    border: 1px solid #07b2d4;
}
.public-stage-wrapper h3 {
    font-weight: 600 !important;
    font-size: 18px;
    margin-bottom: 15px;
}
.limit-wrap {
    border-radius: 20px;
    padding: 25px;
    align-items: center;
    justify-content: space-between;
}
.limit-wrap .info {
    width: 128px;
}
.limit-wrap .info p {
    margin: 0 !important;
    font-size: 14px !important;
    color: #fff !important;
    font-weight: 600 !important;
}
.limit-wrap .info span {
    color: #a4a7a9;
    font-size: 14px;
    font-weight: 300;
}
.limit-wrap .form-group {
    width: 170px;
    margin: 0 auto !important;
    max-width: calc(100% - 330px);
}
.limit-wrap .form-group input {
    outline: 0;
    height: 42px;
    padding: 0 20px;
    text-align: center;
}
.limit-wrap .form-group:before {
    width: 36px;
    height: 35px;
    top: 3.5px;
    left: -16px;
}
.limit-wrap .form-group:after {
    width: 36px;
    height: 35px;
    top: 3.5px;
    right: -16px;
}
.limit-wrap .form-group img {
    position: absolute;
    z-index: 3;
    top: 7px;
}
.limit-wrap .form-group img.increment {
    left: -12px;
}
.limit-wrap .form-group img.decrement {
    right: -12px;
}
.limit-wrap .common-btn {
    width: 150px;
    height: 40px;
    background-size: 150px 40px;
}
.ecosystem {
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/ecosystem-bg.png);
    background-size: cover;
    background-position: bottom;
    padding: 80px 0;
}
.edit-profile .common-btn {
    margin: 40px auto 0;
}
.edit-profile p {
    max-width: 800px;
    margin: 0 auto 40px;
}
.edit-profile .img-wrap {
    width: 278px;
    height: 278px;
    border-radius: 12px;
    padding: 12px;
    border: 1px solid #07b2d4;
    position: relative;
    margin: 0 auto 30px;
}
.edit-profile .img-wrap img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}
.edit-profile .img-wrap img.edit-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    bottom: 20px;
    right: 20px;
    border-radius: 0;
}
.edit-profile .img-wrap input {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}
.explore.pr {
    background-image: none;
    padding: 0 0 100px;
}
.profile .bottom-info,
.profile .inner .bottom span.address {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
}
.profile .inner {
    flex-wrap: wrap;
    padding: 30px;
    border-radius: 16px;
}
.filters-wrap,
.profile .inner .content {
    justify-content: space-between;
    display: flex;
}
.profile .inner .img-wrap {
    width: 192px;
    height: 192px;
    border: 1px solid #07b2d4;
    border-radius: 12px;
    overflow: hidden;
    margin: 0;
}
.profile .inner .img-wrap img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile .inner .content {
    width: calc(100% - 192px);
    padding-left: 30px;
    flex-direction: column;
}
.profile .inner .upper > div {
    width: calc(100% - 222px);
    padding-right: 10px;
}
.profile .inner .upper h3 {
    font-weight: 600 !important;
    font-size: 24px;
    margin-bottom: 25px !important;
}
.profile .inner .upper ul {
    list-style: none;
    display: flex;
    gap: 10px;
}
.profile .inner .upper ul li {
    padding: 0;
    margin: 0;
    background-image: none;
}
.profile .inner .bottom span.address {
    font-size: 14px;
    -webkit-background-clip: text;
    max-width: 170px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
}
.profile .inner .bottom .address {
    display: flex;
    align-items: flex-end;
}
.profile .inner .bottom .address img {
    margin-left: 5px;
}
.profile .inner .common-btn {
    width: 204px;
    height: 46px;
    padding-right: 20px;
}
.profile .bottom-info {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 25px;
    min-height: 40px;
}
.collections-info {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 30px;
}
.collections-info p {
    background: #1c2228;
    padding: 7px 10px;
    border-radius: 8px;
    font-size: 14px;
    border: 1px solid #025161;
}
.profile .bottom-info span {
    display: block;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}
.profile .bottom-info label {
    display: block;
    color: #262e36;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.filters-wrap {
    width: 100%;
    margin-bottom: 40px;
}
.filters-wrap select {
    color: #ebebeb;
    border: 1px solid #07b2d4;
    border-radius: 6px;
    background: #1c2228;
    padding: 10px;
    width: 110px;
    outline: 0;
}
.filters-wrap .form-group {
    width: calc(100% - 180px);
    margin: 0 0 0 30px !important;
}
.percentage-btn {
    display: flex;
    justify-content: space-between;
    gap: 2%;
}
.percentage-btn .common-btn {
    margin: 0 !important;
    width: 22%;
    background-size: 120px 40px;
}
.game video,
.game:after {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
}
.game {
    padding: 120px 0;
    position: relative;
    overflow: hidden;
}
.game:after {
    content: "";
    background: rgba(0, 0, 0, 0.8);
}
.game .inner,
.swap .swap-head {
    position: relative;
    display: flex;
}
.modal-body .clear,
.swap .btn-wrap button {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
}
.game video {
    object-fit: cover;
}
.game .inner {
    border: 1px solid #07b2d4;
    border-radius: 16px;
    padding: 50px 20px;
    min-height: 564px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.swap .btn-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.modal-body .clear {
    float: right;
    font-size: 16px;
    font-weight: 600;
    -webkit-background-clip: text;
}
.swap .btn-wrap button {
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 15px;
    padding: 10px;
    min-width: 155px;
}
.swap .form-group {
    margin: 0 auto 20px !important;
    width: 500px;
}
.swap .inner {
    max-width: 560px;
    margin: 0 auto;
}
.swap .swap-head {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.swap .swap-head p {
    margin: 0 !important;
    font-size: 16px !important;
    cursor: pointer;
}
.swap .swap-head h3 {
    font-size: 24px !important;
    font-weight: 600 !important;
}
.form-group .input-wrap {
    padding: 10px 15px;
    display: flex;
}
.form-group .input-wrap input {
    padding: 0 10px 0 0;
    border: 0 !important;
    background: 0 0 !important;
    height: 100%;
    width: calc(100% - 170px);
}
.swap-icon {
    margin: 0 auto 20px;
    display: block;
    cursor: pointer;
}
.swap .input-wrap span {
    width: 170px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border-left: 1px solid #07b2d4;
    line-height: 1;
    cursor: pointer;
}
.swap .input-wrap span img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}
header .logo img,
.scene {
    width: 74px;
    border-radius: 50%;
}
.select-token .btn-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 30px;
    justify-content: flex-start;
    align-items: flex-start;
}
.select-token .btn-wrap button {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    border: 0;
    padding: 8px 10px;
    border-radius: 4px;
    min-width: 150px;
    justify-content: center;
}
.form-group.search input {
    background-image: url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/search-normal.svg);
    background-size: 24px !important;
    background-position: 0 50%;
    background-repeat: no-repeat;
    padding-left: 35px;
}
.common-modal.react-responsive-modal-modal.select-token .btn-wrap .common-btn {
    margin: 0 !important;
    width: 130px;
    height: 40px;
    background-size: 130px 40px;
}
.common-modal.react-responsive-modal-modal.select-token .btn-wrap .common-btn img {
    position: static;
    margin-right: 10px;
}
.common-modal label {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}
.common-modal.react-responsive-modal-modal.swap-modals h2 {
    text-align: left;
    font-family: "Gang Wolfik" !important;
    font-size: 32px !important;
}
.common-modal.react-responsive-modal-modal.swap-modals {
    width: 640px !important;
}
.explore.swap .inner > .common-btn {
    margin: 50px auto 0;
}
.dropdown-wrapper {
    position: absolute;
    max-width: 360px;
    z-index: 10;
    background: #1c2228;
    border-radius: 15px;
    padding: 25px 20px;
    right: 0;
    top: 100%;
}
.dropdown-wrapper .close-btn {
    background: 0 0;
    border: 0;
    right: 20px;
    top: 20px;
    position: absolute;
}
.dropdown-wrapper ul li {
    background-image: none;
    padding: 0;
    font-style: normal;
}
.explore .dropdown-wrapper .common-btn {
    margin: 0 !important;
    width: 140px;
    background-size: 140px 35px;
    padding: 0;
    height: 100%;
    position: relative;
    right: -25px;
}
.swap .dropdown-wrapper .input-wrap input {
    width: calc(100% - 140px);
}
.swap .dropdown-wrapper .common-btn img {
    position: absolute;
    right: 3px;
    width: 28px;
    top: 3px;
}
.common-modal .form-group .input-wrap input {
    width: 40%;
}
.common-modal .form-group .input-wrap span {
    border-left: 1px solid #07b2d4;
    line-height: 2;
    padding-left: 10px;
    width: 60%;
}
.form-group-wrapper > div {
    width: 49%;
}
.common-modal span.tier {
    display: block;
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
    font-size: 16px;
    font-weight: 600;
    -webkit-background-clip: text;
    margin: -10px 0 20px;
}
.common-modal .form-group.inc-dec-wrap input {
    padding-right: 78px;
}
.common-modal .form-group.inc-dec-wrap img {
    position: absolute;
    top: 7px;
    z-index: 3;
}
.common-modal .form-group.inc-dec-wrap img.increment {
    right: -16px;
}
.common-modal .form-group.inc-dec-wrap img.decrement {
    right: 30px;
}
.form-group.liquidity-amount span {
    position: absolute;
    color: #fff;
    font-size: 16px;
    right: 0;
    z-index: 4;
    top: 17px;
}
.vesting-chart {
    padding: 70px 0 90px;
}
.vesting-chart .apexcharts-xaxis-title text,
.vesting-chart .apexcharts-yaxis-title text {
    fill: #07b2d4;
    font-size: 13px;
    font-weight: 600;
}
.vesting-chart .apexcharts-xaxis-label tspan,
.vesting-chart .apexcharts-yaxis-label tspan {
    color: #fff;
    font-size: 14px;
    fill: #fff;
}
.vesting-chart .content p {
    margin-bottom: 5px;
    font-size: 14px;
}
.home .ecosystem .head,
.home .join-us h2,
.home .tokenomics .head,
.security .head {
    margin-bottom: 40px;
}
footer {
    padding: 25px 0;
    border-top: 1px solid #07b2d4;
}
footer .copyright p {
    font-size: 12px;
    color: #fff;
    margin: 0;
    text-align: center;
}
footer .inner ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 0;
    margin: 0;
}
footer .inner ul li {
    margin: 0;
    padding: 0;
    background-image: none;
}
.home ul li {
    padding: 0;
    background-image: none;
}
.dragon-btn {
    background-image: url(./static/images/drgn-btn.png);
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 20px;
    font-family: thewitcher !important;
    padding: 17px 20px;
    min-width: 248px;
    text-align: center;
    transition: 0.3s ease-in-out;
    background-color: transparent;
    box-shadow: none;
    border: 0;
}
.dragon-btn:hover {
    transform: translateY(-4px);
}
.scene {
    height: 74px;
    perspective: 160px;
}
.coin {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    cursor: pointer;
    animation: 10s linear infinite spin;
}
@keyframes spin {
    0% {
        transform: rotate3d(0, 1, 0, 0);
    }
    100% {
        transform: rotate3d(0, 1, 0, 360deg);
    }
}
.home {
    background: #0c0e11;
}
.home header {
    background: rgba(1, 2, 5, 0.1);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}
.home .navbar {
    padding: 15px 0 !important;
}
.home .navbar ul li a {
    font-family: thewitcher !important;
    font-size: 16px;
}
.home .intro {
    padding: 105px 0 100px;
    text-align: center;
}
.home .intro h1 {
    font-size: 128px !important;
}
.home .intro:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(67.99% 82.47% at 46.1% 15.5%, rgba(1, 2, 5, 0) 0, #010205 100%);
}
.home .intro .content p {
    max-width: 772px;
    margin: 0 auto 50px;
}
.home .benefits.hederagon {
    background-image: url(./static/images/dracarys-bg.jpg);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0;
    z-index: 5;
}
.home .benefits.hederagon:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(65.36% 47.97% at 41.68% 50%, rgba(1, 2, 5, 0) 0, #010205 100%);
}
.home .about .container,
.home .benefits .container,
.home .benefits.hederagon .container,
.home .connect-wrap img,
.home .ecosystem .container,
.home .faqs .container,
.home .join-us .container,
.home .road-container,
.home .security .container,
.home .tokenomics .container,
.home .vesting-chart .container {
    position: relative;
    z-index: 2;
}
.home .benefits.hederagon ul {
    margin-top: -100px;
}
.home .benefits.hederagon ul li {
    background: linear-gradient(27.09deg, #d9d9d9 10.37%, rgba(115, 115, 115, 0.01) 76.69%);
    backdrop-filter: blur(8px);
    border-radius: 24px;
    padding: 20px;
    margin: 0 0 -100px;
    font-style: normal;
    font-size: 20px;
    font-weight: 500;
    min-height: 270px;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    border: 1px solid #666;
    flex-direction: column;
    justify-content: flex-end;
}
.home .about li,
.home .two-wrap .benefits ul li {
    background-image: url(./static/images/li-bg.png);
    min-height: 72px;
    font-style: normal;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    width: 100%;
    text-align: center;
}
.home .benefits.hederagon ul li ul li {
    background: 0 0;
    border: 0;
    margin: 5px 0 0;
    padding: 0 0 0 20px;
    min-height: unset;
    backdrop-filter: unset;
    position: relative;
}
.home .benefits.hederagon ul li ul li:before {
    position: absolute;
    content: "";
    width: 14px;
    height: 2px;
    left: 0;
    top: 13px;
    background: #fff;
}
.home .benefits.hederagon ul li.main:first-child {
    z-index: 4;
}
.home .benefits.hederagon ul li.main:nth-child(2) {
    z-index: 3;
    margin-bottom: -40px;
}
.home .benefits.hederagon ul li.main:nth-child(3) {
    z-index: 2;
}
.home .about:after {
    background-image: url(./static/images/about-bg2.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    mix-blend-mode: color-dodge;
}
.home .about h2 {
    font-size: 120px !important;
}
.home .about > img {
    position: absolute;
    z-index: 4;
    left: -44px;
    bottom: -41px;
    max-width: 48%;
}
.home .about > img.scratch {
    right: 20px;
    left: auto;
    top: 0;
    bottom: auto;
}
.home .about img.scratch {
    position: absolute;
    mix-blend-mode: soft-light;
    z-index: 2;
}
.home .about img.scratch.cen {
    position: absolute;
    mix-blend-mode: soft-light;
    z-index: 2;
    bottom: 150px;
    top: auto;
    right: 55%;
}
.home .about li {
    align-items: center;
    justify-content: center;
    padding: 5px 100px;
}
.home .about p {
    line-height: 2;
    font-style: italic;
    font-size: 17px;
}
.home .security {
    background-image: url(./static/images/security-bd-new.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.home .about:before,
.home .ecosystem:after,
.home .faqs:after,
.home .game:before,
.home .join-us:after,
.home .security:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(51.36% 52.17% at 54.14% 54.85%, rgba(1, 2, 5, 0) 0, #010205 100%);
}
.home .security .security-card {
    background: linear-gradient(221.31deg, rgba(29, 32, 34, 0) 0, rgba(21, 26, 31, 0.9) 47.89%, #151a1f 100%);
    border: 1px solid #9b9b9b;
    height: 100%;
}
.home .join-us:after,
.two-wrap.charts:after {
    background: radial-gradient(65.36% 47.97% at 41.68% 50%, rgba(1, 2, 5, 0) 0, #010205 100%);
}
.home .security .security-card h3 {
    background: 0 0;
    color: #fff;
    font-weight: 600 !important;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
}
.home .ecosystem h2,
.home .faqs h2,
.home .tokenomics h2,
.home .two-wrap .benefits h2 {
    font-size: 100px !important;
}
.home .two-wrap .benefits ul li {
    align-items: center;
    justify-content: center;
    padding: 5px 70px;
    font-size: 17px;
}
.two-wrap {
    position: relative;
    background-image: url(./static/images/fog.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.two-wrap:after {
    background: radial-gradient(65.91% 50% at 53.93% 50%, rgba(1, 2, 5, 0) 0, #010205 100%);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.home .ecosystem,
.home .faqs,
.home .join-us,
.home .tokenomics,
.home .vesting-chart {
    background-position: center;
    background-size: cover;
}
.two-wrap .inner {
    max-width: 1070px;
    margin: 0 auto;
}
.two-wrap .benefits .btn-wrap {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.staking .box .img-wrap,
.view-token .filter-wrap {
    align-items: center;
    display: flex;
}
.home .tokenomics,
.home .vesting-chart {
    background-image: url(./static/images/tokenimics-bg-new.png);
    padding: 100px 0;
    position: relative;
}
.two-wrap .vesting-chart .content {
    margin-top: 50px;
}
.home .vesting-chart {
    padding-top: 0;
}
.home .tokenomics {
    padding-bottom: 50px;
}
.home .ecosystem {
    position: relative;
    background-image: url(./static/images/ecosystem-bg-new.png);
}
.home .join-us {
    background-image: url(./static/images/join-bg.png);
    position: relative;
    margin: 0;
}
.home .join-us:after {
    right: 0;
    transform: none;
}
.home .join-us h2 {
    font-size: 50px !important;
}
.home .join-us p {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 18px;
}
.home .faqs {
    position: relative;
    background-image: url(./static/images/faqs-bg.png);
    padding: 70px 0;
}
.home .faqs .content {
    max-width: 720px;
    margin: 0 auto;
}
.home footer {
    border-top: 0;
    background: #010205;
}
.home .faqs .content > div {
    background: linear-gradient(27.09deg, #d9d9d9 -9.63%, rgba(115, 115, 115, 0.01) 76.69%);
    border: 1px solid #696969;
}
.home .game:before {
    background: radial-gradient(65.91% 50% at 53.93% 50%, rgba(1, 2, 5, 0) 0, #010205 100%);
    z-index: 2;
}
.home .game:after {
    background: rgba(0, 0, 0, 0.3);
}
.home .game .inner {
    border: 0;
    max-width: 1000px;
    margin: 0 auto;
}
.home .game .inner p {
    color: #fff;
    font-size: 18px;
}
.home .roadmap {
    overflow: hidden;
}
.home .phase .inner {
    background: 0 0;
    max-width: 100%;
    width: 100%;
    padding: 0 40px 0 0;
}
.home .phase .inner h3 {
    background: 0 0;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    color: #fff;
    font-weight: 600 !important;
    margin-bottom: 20px !important;
}
.home .phase .inner span {
    display: block;
    margin-bottom: 20px;
}
.home .connect-wrap {
    margin-bottom: 20px;
    position: relative;
}
.home .connect-wrap::before {
    position: absolute;
    left: 44px;
    top: 21px;
    content: "";
    width: 100%;
    height: 6px;
    background: linear-gradient(0deg, #666 0, #6d6d6d 12%, #838383 31%, #a6a6a6 55%, #d7d7d7 81%, #fff 100%);
}
.staking .box .btn-wrap button {
    width: 140px;
    height: 35px;
    background-size: 140px 35px;
    margin: 0 !important;
}
.staking .box .img-wrap {
    border: 1px solid #07b2d4;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    padding: 20px;
    max-width: 100%;
    background: #023e4a;
    height: 500px;
    justify-content: center;
}
.staking .box .img-wrap img {
    max-height: calc(100% - 40px);
    object-fit: contain;
}
.staking .box .info-box {
    background: #023e4a;
    padding: 20px;
    border: 1px solid #07b2d4;
    margin-top: 20px;
    border-radius: 8px;
}
.Toastify__toast {
    background: #1c2228 !important;
    border-radius: 10px !important;
}
.Toastify__toast--info svg {
    fill: #07b2d4;
}
.Toastify__toast--info {
    border: 1px solid #07b2d4;
}
.Toastify__toast--info .Toastify__close-button,
.Toastify__toast--info .Toastify__toast-body > div {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Toastify__toast--info .Toastify__progress-bar,
.copy-wrap a {
    background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
}
.Toastify__toast--success svg {
    fill: green;
}
.Toastify__toast--success {
    border: 1px solid green;
}
.Toastify__toast--success .Toastify__close-button,
.Toastify__toast--success .Toastify__toast-body > div {
    color: green;
}
.Toastify__toast--success .Toastify__progress-bar {
    background: green;
}
.Toastify__toast--error svg {
    fill: red;
}
.Toastify__toast--error {
    border: 1px solid red;
}
.Toastify__toast--error .Toastify__close-button,
.Toastify__toast--error .Toastify__toast-body > div {
    color: red;
}
.Toastify__toast--error .Toastify__progress-bar {
    background: red;
}
.explore.view-token-wrap .card-inner > img {
    margin-bottom: 15px;
}
.explore.view-token-wrap .common-btn {
    margin-top: 0;
    margin-bottom: 20px;
    width: 175px;
    height: 40px;
}
.card-inner .wallet-address {
    padding: 5px 12px;
    border: 1px solid #07b2d4;
    border-radius: 4px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-inner .wallet-address span {
    max-width: calc(100% - 25px);
}
.card-inner .wallet-address img {
    width: 18px;
}
.copy-wrap {
    max-width: 420px;
    margin: 0 auto;
}
.copy-wrap a span {
    display: block;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: initial;
    color: #fff;
    font-size: 14px;
    background: 0 0;
    max-width: calc(100% - 60px);
    margin-right: 5px;
}
.copy-wrap a {
    display: block;
    font-size: 20px;
    font-weight: 600;
    -webkit-background-clip: text;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.team.partners img{
    max-width: 200px;
}

@media screen and (max-width: 1199px) {
  .navbar ul{
    gap: 20px;
    width: calc(50% - 70px);
  }

  .navbar ul li a{
    font-size: 23px;
  }
  .home  .navbar ul li a{
    font-size: 14px;
  }

    .tokenomics .inner .chart-wrap .content h3 {
        font-size: 36px;
    }
    .tokenomics .inner .chart-wrap .content span {
        font-size: 28px;
    }
    .navbar ul {
        width: calc(50% - 87px);
    }
    h1,
    h2 {
        font-size: 40px !important;
    }
    .about .content,
    .img-wrap,
    .intro .content {
        margin-bottom: 30px;
    }
    .faqs .img-wrap {
        margin-bottom: 0;
    }
    .tokenomics .inner .chart-wrap {
        width: 430px;
    }
    .tokenomics .inner .table-wrap table tr th {
        padding: 12px 6px 0;
    }
    .home .about h2,
    .home .intro h1 {
        font-size: 100px !important;
    }
    .home .benefits.hederagon ul li {
        font-size: 16px;
    }
    .home .about li {
        padding: 5px 50px;
        min-height: 62px;
    }
    .home .two-wrap .benefits ul li {
        padding: 5px 40px;
    }
    .home .roadmap {
        padding-bottom: 50px;
    }
    .home .game .inner {
        min-height: 350px;
    }
    .home .about > img {
        z-index: 2;
        left: -240px;
        bottom: -36px;
        max-width: 78%;
    }
}
@media screen and (max-width: 991px) {
  .profile .inner{
    flex-direction: column;
  }

  .profile .inner .img-wrap{
    margin-bottom: 30px;
  }

  .profile .inner .content{
    padding-left: 0;
    width: 100%;
  }
    .home .about h2,
    .home .about p,
    .home .benefits.hederagon h2,
    .home .intro,
    .home .security .security-card,
    .intro {
        text-align: center;
    }
    .staking .box .btn-wrap button {
        width: 95px;
        height: 30px;
        background-size: 95px 30px;
        margin: 0 !important;
        letter-spacing: 0;
        font-size: 14px;
    }
    .staking .box .img-wrap {
        height: 400px;
    }
    .security .col-lg-6:first-child {
        margin-bottom: 30px;
    }
    .buy-arbitrum,
    .explore,
    .mint-nft {
        padding-top: 260px;
    }
    .navbar{
      padding: 15px 0 !important;
    }
    .intro {
        padding: 280px 0 20px;
    }
    .intro .common-btn {
        margin: 0 auto;
    }
    .navbar {
      flex-wrap: wrap;
      justify-content: center;
  }
  .navbar ul {
      order: 2;
      width: 100%;
      gap: 30px;
      justify-content: center;
      margin: 0;
  }
  .navbar{
    gap: 12px;
  }
    .about,
    .benefits,
    .roadmap {
        padding: 50px 0;
    }
    .about .content p {
        margin-bottom: 35px;
    }
    .head {
        margin-bottom: 25px;
    }
    .faqs {
        padding-top: 50px;
    }
    .faqs .head,
    .home .benefits.hederagon .img-wrap {
        margin-bottom: 40px;
    }
    .tokenomics .inner {
        flex-direction: column;
        row-gap: 35px;
    }
    .home .game .inner {
        min-height: auto;
    }
    .home .ecosystem h2,
    .home .faqs h2,
    .home .intro h1,
    .home .tokenomics h2,
    .home .two-wrap .benefits h2 {
        font-size: 80px !important;
    }
    .intro .head span {
        font-size: 40px;
    }
    .home .benefits.hederagon ul {
        max-width: 350px;
        margin: 0 auto;
    }
    .home .benefits.hederagon {
        padding: 80px 0;
    }
    .home .about {
        padding: 200px 0 80px;
    }
    .home .about h2 {
        font-size: 80px !important;
    }
    .home .about .head,
    .home .tokenomics .container {
        max-width: 100%;
    }
    .home .benefits .img-wrap {
        text-align: center !important;
        margin-bottom: 0;
    }
    .home .tokenomics {
        padding: 80px 0 50px;
    }
    .home .navbar ul {
        gap: 28px;
    }
    .home .navbar {
        gap: 12px;
    }
    .navbar .logo-wrap {
        order: 1;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .home .intro {
        padding: 210px 0 80px;
    }
    .leaderboard-table-wrap table tr td:first-child:before,
    .leaderboard-table-wrap table thead {
        display: none;
    }
    .leaderboard-table-wrap table tr td::before {
        content: attr(data-label);
        font-weight: 700;
        width: 50%;
        text-align: left;
    }
    .leaderboard-table-wrap table tr td:first-child {
        width: 100%;
        justify-content: center;
    }
    .leaderboard-table-wrap table tr td {
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: space-between;
        word-break: break-all;
    }
    .leaderboard-table-wrap table tr {
        border-bottom: 1px solid #07b2d4;
        display: block;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .leaderboard-table-wrap table tr:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 767px) {
    .buy-arbitrum,
    .explore,
    .intro,
    .mint-nft {
        padding-top: 280px;
    }
    .navbar,
    footer .inner ul {
        gap: 20px;
    }
    #tokenomics .inner .table-wrap table tr td:first-child:before,
    #tokenomics .table-wrap table thead {
        display: none;
    }
    #tokenomics .table-wrap table tr td::before {
        content: attr(data-label);
        font-weight: 700;
        width: 50%;
        text-align: left;
    }
    #tokenomics .inner .table-wrap table tr td:first-child {
        width: 100%;
        justify-content: center;
    }
    #tokenomics .table-wrap table tr td {
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: space-between;
    }
    #tokenomics .inner .table-wrap table tr {
        border-bottom: 1px solid #07b2d4;
        display: block;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    #tokenomics .inner .table-wrap table tr:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .home .phase .inner {
        padding: 0 30px;
    }
    .home .connect-wrap::before {
        width: calc(100% + 22px);
    }
    .staking .box {
        margin-bottom: 70px;
    }
    .staking .col-md-6:last-child .box {
        margin-bottom: 0;
    }
    .staking .box .img-wrap {
        height: auto;
        min-height: 300px;
    }
    .intro:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
    }
    .home .intro:after {
        background: radial-gradient(67.99% 82.47% at 46.1% 55.5%, rgba(1, 2, 5, 0) 0, #010205 100%);
    }
    .intro img {
        object-position: 46% 0;
    }
    .intro {
        background-position: 83% top;
    }
    .navbar {
        flex-wrap: wrap;
        justify-content: center;
    }
    .navbar ul {
        order: 2;
        width: 100%;
        gap: 30px;
        justify-content: center;
        margin: 0;
    }
    .explore .card-inner > img {
        height: auto;
    }
    .security {
        padding: 80px 0;
    }
    .security .head,
    footer .copyright p {
        text-align: center;
    }
    .benefits ul li {
        padding-left: 60px;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .phase .phase-number {
        width: 85px;
        height: 85px;
        margin-right: 15px;
        background-size: 85px;
        font-size: 30px;
    }
    .phase:before {
        width: 2px;
        height: calc(100% - 46px);
        top: 100px;
        left: 42px;
        background-image: none;
        background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
    }
    .phase .inner {
        width: calc(100% - 100px);
        padding: 15px;
    }
    .phase .inner h3 {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .join-us {
        padding: 50px 0;
    }
    .tokenomics .inner .table-wrap table tr td {
        padding: 12px 3px;
    }
    .tokenomics .inner .chart-wrap .content h3 {
        font-size: 38px;
    }
    .tokenomics .inner .chart-wrap .content span {
        font-size: 32px;
    }
    footer .inner {
        flex-direction: column;
        row-gap: 15px;
    }
    .faqs .row {
        flex-direction: column-reverse;
    }
    .home .intro h1 {
        font-size: 65px !important;
    }
    .home h2 {
        font-size: 50px !important;
    }
    .home .about h2,
    .home .ecosystem h2,
    .home .faqs h2,
    .home .tokenomics h2,
    .home .two-wrap .benefits h2 {
        font-size: 60px !important;
    }
}
@media screen and (max-width: 575px) {
  .navbar ul, .home .navbar ul{
    gap: 16px;
  }
    .navbar ul li a {
        font-size: 22px;
        letter-spacing: 0;
    }
    footer .copyright p {
        font-size: 12px;
    }
    .tokenomics .inner .table-wrap {
        width: 100%;
    }
    .tokenomics .inner .chart-wrap {
        width: 350px;
    }
    .tokenomics .inner .chart-wrap .content h3 {
        font-size: 30px;
    }
    .home .two-wrap .benefits ul li {
        min-height: 50px;
    }
}



.outer{
  display: flex;
}

.outer .logo-wrap img{
  width: 96px;
  height: 96px;
  border-radius: 50%;
}

.outer .logo-wrap a{
  margin-right: 40px;
}

.outer .logo-wrap{
  text-align: center;
  margin-bottom: 30px;
}

.outer .sidebar{
  height: 100vh;
  overflow: auto;
  background: linear-gradient(263.33deg, #15CDF2 0%, #4589E0 100%);
  padding: 20px 0 30px 40px;
  width: 310px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.outer .right-wrap{
  width: calc(100%);
  /* width: calc(100% - 310px); */
  /* margin-left: 310px; */
  background-image:url(https://ox-burn-assets.s3.us-east-2.amazonaws.com/gradient-banner.png);
  background-size: cover;
  background-position: center;
}

.no-top.h-full{
  display: flex !important;
  min-height: calc(100vh - 76px);
  align-items: center;
  justify-content: center;
}
.outer .right-wrap .profile, .outer .right-wrap .explore, .outer .right-wrap .dao-wrapper{
  background-image: none;
}
.sidebar ul li{
  background-image: none;
  font-style: normal;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.sidebar ul li a{
  color: #fff;
  font-family:Montserrat !important;
  font-weight: 500;
  font-size: 17px;
  position: relative;
  display: block;
  height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sidebar ul li a span{
  width: 100%;
}

.sidebar ul li a:hover{
  background: #1c2228;
}

.sidebar ul li a:before{
  position: absolute;
    content: '';
    width: 40px;
    height: 42px;
    opacity: 0;
    border-right: 0;
    border-top: 0;
    left: 0;
    top: 4px;
    border-radius: 10px;
    transform: rotate(45deg);
    left: -21px;
    background: #1c2228;
    z-index: -1;
}

.sidebar ul li a:hover:before{
  opacity: 1;
}

.explore.no-top, .profile.no-top, .dao-wrapper.no-top, .mint-nft.no-top{
  padding-top: 15px;
  display: block;
}

.explore.no-top .container, .profile.no-top .container, .dao-wrapper.no-top .container{
  max-width: 100% !important;
  padding: 0 30px;
}

.dashboard-wrap{
  padding: 0 30px 30px;
}
.dashboard-wrap h1, .dao-wrapper h1{
  letter-spacing: 5px;
}

.dashboard-wrap .boxes{
  display: flex;
  justify-content: space-between;
  margin: -10px;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
}

.dashboard-wrap .boxes .box{
  width: calc(33.33% - 20px);
  margin: 10px;
  min-height: 150px;
  border-radius: 2px;
  border: 1px solid #07b2d4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  transition: all ease-in-out 0.2s;
}
.dashboard-wrap .boxes .box:hover{
  background: #07b2d4;
}
.dashboard-wrap .boxes .box span{
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.edit-profile{
  padding-top: 150px;
}

.countdown {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-bottom: 50px;
  }
  
  .time-unit {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #333; /* Default background color */
  }
  
  .inner-circle {
    width: 97%;
    height: 97%;
    background-color: #21272e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    position: relative;
  }
  
  .time-unit p {
    font-size: 32px;
    margin: 0;
    color: #fff;
  }
  
  .time-unit span {
    display: block;
    font-size: 14px;
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: -30px;
    text-transform: uppercase;
  }

  .team{
    padding: 100px 0 50px;
    position: relative;
  }

  .team.partners{
    padding: 100px 0 70px;
  }
  .team h2{
    text-align: center;
    margin-bottom: 50px;
  }
  .team:after{
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(51.36% 52.17% at 54.14% 54.85%, rgba(1, 2, 5, 0) 0, #010205 100%);
  }

  .team .container{
    position: relative;
    z-index: 2;
  }

  .team .team-member{
    text-align: center;
  }

  .team .team-member img{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: 0 auto 20px;
    object-fit: cover;
    filter: grayscale();
  }

  .team .team-member h3{
    font-size: 18px;
    margin-bottom: 15px;
  }

  .team .team-member p{
    background-image:url(./static/images/sub-text-pattern.png)!important;-webkit-background-clip:text;
    font-family: thewitcher !important;
    font-size: 18px;
    opacity: 0.8;
    line-height: 1.1;
  }
  .sub-text-pattern{}

  .launchdao-head{
    display: flex;
    align-items: flex-end;
    background: #262b31;
    padding: 40px 24px;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .launchdao-head p{
    font-size: 16px;
    margin-bottom: 0 !important;
  }
  
  .launchdao-steps-boxes-wrap{
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }
  
  .launchdao-steps-boxes-wrap .step-box{
    width: calc(33.33% - 16px);
    margin: 8px;
    background-color: #262b31;
    padding: 16px;
    display: flex;
  }
  
  .launchdao-steps-boxes-wrap .step-box .icon-wrap{
    background: #212529;
    height: 96px;
    width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .launchdao-steps-boxes-wrap .step-box .icon-wrap img{
    width: 64px;
    height: 64px;
    object-fit: contain;
  }
  
  .launchdao-steps-boxes-wrap .step-box .step-box-info{
    width: calc(100% - 96px);
    padding-left: 12px;
  }
  
  .launchdao-steps-boxes-wrap .step-box .step-box-info span{
    color: #A9AAAD;
    font-size: 15px;
    display: block;
    margin-bottom: 16px;
  }
  
  .launchdao-steps-boxes-wrap .step-box .step-box-info h3{
    color: #fff;
    font-size: 18px;
    margin-top: 0;
  }
  
  .steps-form-wrapper{
    background: #262b31;
    padding: 32px 24px 40px;
    position: relative;
    margin-bottom: 72px;
  }
  
  .progress-head{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 7px;
  }
  
  .progress-head h3{
    color: #FFF;
    font-size: 18px;
    margin: 0 !important;
    font-weight: 500 !important;
  }
  
  .progress-head span{
    color: #A9AAAD;
    font-size: 15px;
  }
  
  .steps-form-wrapper .step > p{
    color: #A9AAAD;
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 30px;
  }
  
  .steps-form-wrapper .step .btn-wrap{
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -80px;
  }
  
  .steps-form-wrapper .step .btn-wrap .btn{
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 44px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 35px;
  }
  
  .steps-form-wrapper .step .btn-wrap .btn.back{
    border: 1px solid #07b2d4 !important;
  }
  
  .steps-form-wrapper .step .btn-wrap .btn.back img{
    margin-right: 10px;
  }
  
  .steps-form-wrapper .step .btn-wrap .btn.next{
    background: linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%);
  }
  
  .steps-form-wrapper .step .btn-wrap .btn.next img{
    margin-left: 10px;
  }
  
  .step .form-wrap.dir-col{
    flex-direction: column;
    max-height: 274px;
  }
  .step .form-wrap.dir-col.no-height{
    max-height: initial;
  }
  .step .form-wrap{
    margin: 24px -6px -6px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    flex-wrap: wrap;
    display: flex;
  }
  .step .form-wrap.full-width{
    display: block;
    margin: 0;
  }
  
  .step .form-wrap.full-width >div{
    width: 100%;
    margin: 0;
  }
  .step .form-wrap.full-width >div textarea{
    height: 250px;
  }
  .step .form-wrap >div{
    width: calc(33.33% - 60px);
    margin: 6px;
  }
  
  .step .form-wrap.full-width>div{
    width: 100%;
    margin: 0 0 12px;
  }
  .step .form-wrap input, .step .form-wrap textarea, .step .form-wrap select{
    line-height: 28px;
  }
  
  .step .checkbox-area.form-group{
    width: 100%;
    margin-bottom: 16px;
  }
  .step .form-wrap >div label{
    color: #A9AAAD;
  }
  
  .step .form-wrap >div.addmore button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 12px 25px;
  }
  
  .step .form-wrap >div.addmore button img{
    margin-left: 10px;
  }
  
  .step .form-wrap >div.swap .form-group .input-group{
    background: #3C3F47;
    border: 0;
    height: 70px;
  }
  .step .form-wrap .network-dropdown select{
    padding: 0;
    border: 0 !important;
    outline: 0 !important;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 24px;
  }
  .step .checkbox-area.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .step .checkbox-area.form-group label {
    position: relative;
    cursor: pointer;
    color: #A9AAAD;
    font-size: 15px;
    padding: 0;
  }
  
  .step .checkbox-area.form-group label:before {
    content:'';
    -webkit-appearance: none;
    background: transparent;
    border: 1px solid #07b2d4;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .step .checkbox-area.form-group input:checked + label:before {
    background:linear-gradient(263.33deg,#15cdf2 0,#4589e0 100%);
  }
  
  
  .step .checkbox-area.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 8px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .step .form-wrap .filewrap .upload-btn{
    color: #fff;
    border-radius: 3px;
    font-size: 14px;
    padding: 12px 20px;
    right: 15px;
  }
  
  .step .radio-wrap{
    width: 400px;
    max-width: 100%;
    border-radius: 3px;
    border: 1px solid #07b2d4;
    display: flex;
    padding: 5px;
  }
  
  .step .radio-wrap p{
    width: 50%;
  }
  .step .radio-wrap input[type="radio"] {
    position: absolute;
    left: -9999px;
}
.step .radio-wrap p{
    width: 50%;
    margin: 0 !important;
}
  .step .radio-wrap label{
    border-radius: 3px !important;
    padding: 8px 12px !important;
    text-align: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500 !important;
    margin: 0 !important;
    width: 100% !important;
    color: #fff !important;
  }
  
  .step .radio-wrap input[type="radio"]:checked+label{
    background:linear-gradient(263.33deg,#15cdf2 0,#4589e0 100%);
  }
  
  .radio-select input[type="radio"]{
      position: absolute;
      left: -9999px;
  }
  .radio-select p{
    margin-bottom: 12px;
  }
  
  .radio-select p:last-child{
    margin-bottom: 0;
  }
  .radio-select input[type="radio"]+ label
  {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #A9AAAD;
      font-size: 15px;
      z-index: 1;
  }
  
  .radio-select input[type="radio"] + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: transparent;
      border: 1px solid #07b2d4;
  }
  .radio-select input[type="radio"]:checked + label:after {
      content: '';
      width: 14px;
      height: 14px;
      background:linear-gradient(263.33deg,#15cdf2 0,#4589e0 100%);
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }

  .step .common-btn+.common-btn{
    margin-top: 0 !important;
  }

  .steps-form-wrapper .progress-bar{
    background: #343E47;
    width: 100%;
    height: 12px;
  }
  .steps-form-wrapper .progress-bar-fill{
    background:linear-gradient(263.33deg,#15cdf2 0,#4589e0 100%);
    height: 100%;
  }

  .form-group.checkbox-area:before, .form-group.checkbox-area:after{
    display: none;
  }

  .form-group.checkbox-area, .form-group.checkbox-area{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .leaderboard-main h1{
    letter-spacing: 5px;
  }

  .leaderboard-main table{
    border: 1px solid rgba(255,255,255,0.5);
    width: 100%;
    border-radius: 4px;
  }

  .leaderboard-main table{
    width: 100%;
    border: 1px solid #07b2d4;
    border-radius: 16px;
    border-collapse: separate;
  }

  .leaderboard-main table tr th {
    color: #fff;
    font-size: 18px;
    padding: 15px;
}

.leaderboard-main table tr td {
  color: #fff;
  font-size: 16px;
  padding: 12px 10px;
  border-top: 1px solid rgba(255,255,255,0.1);
}

.leaderboard-main table tr td img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.leaderboard-main table tr th:first-child{
  width: 82px;
  text-align: center;
}

.leaderboard-main table tr td:first-child{
 text-align: center;
}

.leaderboard-main table tr th:nth-child(2){
  width: 120px;
  text-align: center;
}

.leaderboard-main table tr td:nth-child(2){
  text-align: center;
}

.leaderboard-main h2{
  letter-spacing: 3px;
}
.detail-list{
  display: flex;
  justify-content: space-between;
}
.detail-list li{
    width: 100%;
    border: 1px solid #07b2d4;
    border-radius: 16px;
    padding: 0;
    width: 31%;
    background-image: none;
    font-style: normal;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.detail-list li h2{
  font-family:Montserrat,sans-serif !important;
}
.detail-list li p{
  font-size: 25px;
  font-weight: bold;
  font-weight: 600;
  -webkit-background-clip: text !important;
  background: linear-gradient(263.33deg, #15cdf2 0, #4589e0 100%);
  -webkit-text-fill-color: transparent;
  margin: 0 !important;
}
.view-dao.leaderboard-main table tr th:first-child{
  width: auto;
  text-align: left;
}
.view-dao.leaderboard-main table tr td:first-child{
 text-align: left;
}
.view-dao.leaderboard-main table tr th:nth-child(2){
  width: auto;
  text-align: left;
}
.view-dao.leaderboard-main table tr td:nth-child(2){
  text-align: left;
}
.view-dao.leaderboard-main table tr td .common-btn{
  margin: 0 !important;
  width: 160px;
    height: 36px;
    font-size: 15px;
}
.view-dao.leaderboard-main table tr td.green{
  color: #05ff00;
  font-weight: 600;
} 

  @media screen and (max-width: 1199px) {
    .team .team-member{
        margin-bottom: 40px;
    }

    .team{
        padding: 100px 0 10px;
        position: relative;
      }
  }

  @media screen and (max-width: 575px) {
    .countdown {
        gap: 15px;
        padding-bottom: 50px;
      }
      
      .time-unit {
        width: 70px;
        height: 70px;
      }
      
      .inner-circle {
        width: 96%;
        height: 96%;
      }
      
      .time-unit p {
        font-size: 24px;
      }
      
      .time-unit span {
        font-size: 13px;
        bottom: -25px;
      }
    
  }